<template>
  <div id="myModal2" class="modal right fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>

        </div>
        <div class="modal-body">

          <h5 class=" titulo">
          Política de privacidade
          </h5>

          <h6>
            1 - Informações gerais
          </h6>
          <p class="texto">
            A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e
            proteção dos dados pessoais dos usuários e visitantes do aplicativo EASYANEST com a finalidade de demonstrar
            absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são
            coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações
            pessoais. Este documento integra os Termos e Condições Gerais de Uso referidos acima e foi elaborado em
            conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei
            12.965/14)
            (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado em decorrência de eventual
            atualização normativa, razão pela qual se convida o usuário
            a consultar periodicamente esta seção.
          </p>
          <h6>
            2 - Como coletamos os dados sobre o usuário e/ou visitante?
          </h6>
          <p class="texto">
            Os dados pessoais do usuário e visitante são recolhidos pela plataforma da seguinte forma: Quando o usuário
            cria uma conta/perfil no aplicativo EASYANEST: esses dados são os dados de identificação básicos. A partir
            deles, podemos identificar o usuário
            e o visitante, além de garantir uma maior segurança e bem-estar às suas necessidades. Além disso, quando um
            usuário e visitante acessa o aplicativo, as informações sobre interação e acesso são coletadas pela empresa
            para garantir uma melhor experiência ao usuário e visitante. Estes dados podem tratar sobre as
            palavras-chaves utilizadas
            em uma busca, o compartilhamento de um documento específico, comentários, visualizações de páginas, perfis,
            a URL de onde o usuário e visitante provêm,
            o navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.
          </p>
          <h6>
            3 - Quais dados coletamos e quais são nossas finalidades e bases legais?
          </h6>
          <p class="texto">
            3.1 - Os dados de nossos usuários poderão ser coletados de formas distintas e com distintos fins e
            dependendo de determinadas situações, tais como com a intervenção do titular, como no caso do cadastro, como
            também sem a intervenção do titular, em caso de utilização de tecnologias de mercado em suas aplicações web
            como cookies, pixel tags e beacons, sempre em consonância com o principio da minimização de dados exigido
            pela LGPD.

            Vejamos abaixo :
            3.1.1 - Classificação do dado:
            Dados pessoais simples e identificados

            Tipo de dado:
            1-Nome
            2-Sobrenome
            3-Data de nascimento
            4-CPF
            5-Endereço
            6-E-mail
            7-Telefone pessoal

            Finalidade do uso:
            1-Identificar o Titular;
            2-Cumprir as obrigações decorrentes do uso do app EASYANEST;
            3-Informar sobre novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes para a
            manutenção do relacionamento com o Titular;
            4-Promover nossos produtos/serviços;
            5-Responder às solicitações e pedidos de informações do Titular;
            6-conferir às partes segurança jurídica e facilitar a conclusão do negócio;
            7-Portabilidade dos dados cadastrais para outro Controlador do mesmo ramo de nossa atuação, caso solicitado
            pelo Titular;

            Base Legal (art 7º da Lei 13709):
            1-Para a execução de contrato ou de procedimentos preliminares relacionados ao contrato do qual seja parte o
            titular, a pedido do titular dos dados;
            2-Para atender aos interesses legítimos do controlador por meio de tecnologia de CRM (Customer Relationship
            management);
            3-Consentimento expresso: aceitação dos termos de uso e política de privacidade e preenchimento da conta
            /perfil no app EASYANEST de forma voluntária e informado do usuário;
            4-Cumprimento de obrigações legais e regulatórias;
            3.1.2 - Classificação do dado:
            Dados comportamentais, sendo alguns dados pessoais identificáveis

            Tipo de dado:
            1-Endereço IP
            2-Registros de interações com um ou mais apps ou websites
            3-Telas acessadas, dispositivo (versão do sistema operacional, geolocalização, aplicativos instalados, se
            necessário)
            4-Session ID
            5-Cookies

            Finalidade do uso:
            1-Cumprir obrigação estabelecida na Lei Geral de Proteção de Dados – Lei nº 13.709/2018 e ao Marco Civil da
            Internet – Lei 12.965/2014;
            3-Avaliação do uso e utilidade dos serviços que prestamos no CRM da EASYANEST;
            4-interação com outros perfis e usuários, perfis seguidos, endereço de IP) com fins de otimizar o CRM da
            empresa e oferecer serviços e produtos mais customizados para nossos clientes;
            5-Aprimorar o produto e/ou serviço oferecido;
            6-Facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a EASYANEST;
            7-Melhorar a experiência dos usuários;
            8-Fornecer funcionalidades específicas a depender das características básicas do usuário;
            9-Melhorias da plataforma: compreender como o usuário utiliza os serviços da plataforma, para ajudar no
            desenvolvimento de negócios e técnicas;
            10-Previsão do perfil do usuário: tratamento automatizados de dados pessoais para avaliar o uso na
            plataforma;
            11-Fins estatísticos e de segurança;

            Base Legal (art 7º da Lei 13709):
            1-Para atender o legítimo interesse do controlador visando ao uso para a situação concreta de melhoria da
            prestação do serviço;
            2-Cumprimento de obrigações legais e regulatórias;
            3.1.3 - Classificação do dado:
            Dados pessoais sensíveis

            Tipo de dado:
            1-Doenças como hipertensão arterial, arteriosclerose, etc.;
            2-Histórico cirúrgico;
            3-Prescrições em andamento;
            4-Tratamentos médicos anteriores;
            5-Alergias medicamentosas;
            6-Etc.;

            Finalidade do uso:
            1-Armazenar avaliações médicas;
            2-Acesso a histórico de saúde;
            3-Monitorar e conhecer o ciclo de vida do paciente;
            4-Oferecer medicação(ões) adequada(s);
            5-Gerar receita(s) médica(s);
            6-Bem-estar do usuário;
            7-Apresentar anúncios personalizados para o usuário com base nos dados fornecidos;

            Base Legal (art 7º da Lei 13709):
            1-Tutela da saúde;
            2-Obrigação legal e regulatória;
            3-Consentimento expresso e específico do titular;
            3.1.4 - Classificação do dado:
            Dados para concretizar transações

            Tipo de dado:
            1-Número do cartão de crédito, débito ou similar;
            2-Outras informações sobre o cartão, além dos pagamentos efetuados;

            Finalidade do uso:
            1-Emissao de notas fiscais;
            2-Operacionalização e concretização de transações;

            Base Legal (art 7º da Lei 13709):
            1-Obrigação legal e regulatória;
            2-Consentimento expresso do titular;
            3-Eventualmente, para proteção do crédito;
            3.2 - O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente
            ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos
            permanecem aplicáveis.
          </p>
          <h6>
            4 - Deveres e obrigações do titular e da Easyanest
          </h6>
          <p class="texto">
            4.1 - O Titular é o único responsável pela exatidão e veracidade dos dados que informar no ato de
            preenchimento do formulário de cadastro.

            4.2 - Caso identificada falsidade de informações cadastradas, a EASYANEST solicitará ao Titular
            esclarecimentos, podendo, suspender ou bloquear seu acesso se julgá-las inadequadas, se assim for
            necessário.

            4.3 - Na hipótese de mudanças da finalidade para o tratamento de dados pessoais não compatíveis com o
            consentimento original, a EASYANEST informará previamente o Titular sobre as mudanças de finalidade por meio
            do e-mail informado no momento do cadastramento. Caso o tratamento dos dados seja realizado com fundamento
            na base legal “com consentimento”, o titular poderá revogá-lo caso discorde das alterações. No mesmo ato, a
            EASYANEST informará as consequências caso o Titular não concorde com a nova finalidade de tratamento de seus
            dados pessoais.

            4.4 - O Titular se declara ciente de que os dados coletados e as atividades registradas poderão ser
            compartilhados: (a) Com autoridades judiciais, administrativas ou governamentais competentes, sempre que
            houver determinação legal, requerimento, requisição ou ordem judicial; (b) de forma automática em caso de
            operações societárias como fusão, aquisição e incorporação; (c) para operadores que efetuarão o tratamento
            de dados pessoais do Titular e/ou (d) empresas do grupo econômico do qual a EASYANEST é parte integrante.

            4.5 - A EASYANEST não irá comercializar, alugar, ceder, emprestar os dados pessoais
            dos Titulares.

            4.6 - O Titular se declara ciente de que seu login e senha são de uso pessoal e intransferível e que deverá
            mantê-los sob sigilo e em ambiente seguro. O Titular que utilizar login e senha de outra pessoa ou cedê-la a
            outrem para que dela se utilize poderá incorrer no crime tipificado no art. 308, do Código Penal.

            4.7 - EASYANEST garante que somente os empregados devidamente autorizados terão acesso aos dados pessoais
            coletados e sempre respeitando os princípios de proporcionalidade, necessidade e relevância para os
            objetivos do Portal Gluo CRM, além do compromisso de confidencialidade e preservação da privacidade nos
            termos deste Termos de Privacidade.
          </p>
          <h6>
            5 - Por quanto tempo os dados pessoais ficam armazenados?
          </h6>
          <p class="texto">
            5.1 - Os dados pessoais do usuário e/ou visitante são armazenados pela plataforma durante o período
            necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento,
            conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.

            5.2 - Estes poderão ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei
            oferecer outro tratamento.

            5.3 - Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento
            nas seguintes hipóteses previstas no artigo 16 da referida lei:
            I - cumprimento de obrigação legal ou regulatória pelo controlador;
            II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; III -
            transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na Lei
            13.709- a LGPD;
            IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
          </p>
          <h6>
            6 - Segurança dos dados pessoais armazenados
          </h6>
          <p class="texto">
            6.1 - A EASYANEST se compromete a aplicar as medidas técnicas e organizativas aptas
            a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração,
            comunicação ou difusão de tais dados.

            6.2 - Os dados relativos a cartões de crédito são criptografados usando a tecnologia "secure socket layer"
            (SSL) que garante a transmissão de dados de forma segura
            e confidencial, de modo que a transmissão dos dados entre o servidor e o usuário
            ocorre de maneira cifrada e/ou encriptada.

            6.3 - Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No
            entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você
            violar nossos Termos de Serviço.
          </p>
          <h6>
            7 - Os dados pessoais armazenados serão transferidos a terceiros?
          </h6>
          <p class="texto">
            7.1 - Os terceiros indicados recebem os dados na medida do necessário para permitir
            que eles realizem os serviços contratados.

            7.2 - Com relação aos fornecedores de serviços terceirizados como processadores
            de transação de pagamento, informamos que cada qual tem sua própria política de privacidade. Desse modo,
            recomenda-se a leitura das suas políticas de privacidade específicas e respectivas para compreensão de quais
            informações pessoais serão usadas por esses fornecedores.

            7.2.1 - Ao ser redirecionado para um aplicativo ou site de terceiros, você não será mais regido por essa
            Política de Privacidade ou pelos Termos de Serviço da nossa plataforma. Não somos responsáveis pelas
            práticas de privacidade de outros sites
            e lhe incentivamos a ler as declarações de privacidade deles.
          </p>
          <h6>
            8 - Cookies ou dados de navegação
          </h6>
          <p class="texto">
            8.1 - Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e
            visitante e que nele ficam armazenados, com informações relacionadas
            à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e
            são armazenadas pelo navegador do usuário e visitante
            para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar
            os serviços da plataforma.

            8.1.1 - O usuário e o visitante de nosso aplicativo manifestam, de forma expressa
            e inequívoca conhecer e aceitar que pode ser utilizado um sistema de coleta
            de dados de navegação mediante à utilização de cookies.

            8.1.2 - O cookie persistente permanece no disco rígido do usuário e visitante depois que o navegador é
            fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser
            removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois
            que o navegador
            é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da
            plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.

          </p>
          <h6>
            9 - Dos direitos do titular
          </h6>
          <p class="texto">
            9.1 - O Titular tem direito a obter da EASYANEST, em relação aos seus dados pessoais por nós tratados, a
            qualquer momento e mediante requisição gratuita: (a) confirmação da existência de tratamento; (b) acesso aos
            dados; (c) correção de dados incompletos, inexatos ou desatualizados; (d) anonimização, bloqueio ou
            eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD; (e)
            portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, observados
            os segredos comercial e industrial; (f) eliminação dos dados pessoais tratados com o seu consentimento,
            exceto nas hipóteses previstas no item 2.3; (g) informação das entidades públicas e privadas com as quais a
            EASYANEST realizou uso compartilhado de dados; (h) informação sobre a possibilidade de não fornecer
            consentimento e sobre as consequências da negativa; (j) revogação do consentimento, nos termos do art. 8º, §
            5º, da LGPD.
          </p>
          <h6>
            10 - Consentimento
          </h6>
          <p class="texto">
            10.1 - Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentindo
            com a presente Política de Privacidade. Você, como usuário, ao cadastrar-se em nosso app, manifesta conhecer
            e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a
            veracidade das informações por ele disponibilizadas.

            10.2 - O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em
            contato através do e-mail _______ ou por correio enviado ao seguinte endereço: ________

          </p>
          <h6>
            11 - Da atuação perante à autoridade nacional de proteção de dados
          </h6>
          <p class="texto">
            11.1 - A EASYANEST atuará em conjunto com a Autoridade Nacional de Proteção de Dados para zelar pela
            proteção de dados pessoais nos limites da legislação vigente.

            11.2 - O Titular se declara ciente de que a EASYANEST revisará suas diretrizes
            e procedimentos sempre que a ANPD exigir.

            11.3 - Todas as solicitações e/ou questionamentos da ANPD serão prontamente respondidas pelo Encarregado
            (temos que definir quem será o encarregado).
          </p>
          <h6>
            12 - Alterações nesta política de privacidade
          </h6>
          <p class="texto">
            12.1 - Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento, então, é
            recomendável que o usuário e visitante revise-a com frequência.
            As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação
            na plataforma. Quando realizadas alterações os usuários serão notificados.
            Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações,
            o usuário e visitante demonstra sua concordância com as novas normas.

            12.2 - Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser transferidas
            para os novos proprietários para que seja possível
            a permanência dos serviços oferecidos.
          </p>
          <h6>
            13 - Jurisdição para resolução de conflitos
          </h6>
          <p class="texto">
            13.1 - Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o
            Direito brasileiro. Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a
            sede da empresa.
            Fim.
          </p>
        </div>


      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>


export default {
  name: "PoliticaPrivacidade",
  props: {
    visible: Boolean,
    variant: String,
  },
  data() {
    return {
      OpenClose: this.visible
    }
  },
  methods: {
    OpenCloseFun() {
      this.OpenClose = false;
    },
  },
  watch: {
    visible: function (newVal, oldVal) { // watch it
      this.OpenClose = newVal;
      console.log('new' + newVal + '==' + oldVal)
    }
  }
}
</script>

<style scoped>
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal {
  --bs-modal-width: 712px;
  min-width: 320px;
}

.btn-close {
  margin: 40px 0 10px 0px;
  position: absolute;
}

.modal-title {}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 600px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 48px 48px 80px;
  margin-top: 50px;
}

@media (max-width: 570px) {
  .modal.right .modal-body {
    padding-left:  230px;
    margin-top: -50px;
  }
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -620px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 24px 0px 0px 24px;
  border: none;


}

.modal-header {
  border-bottom-color: #EEEEEE;

}

/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909C;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #FFFFFF;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer>a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.titulo {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width: 570px) {
  .titulo {
    font-size: 20px;
    padding: 0px;
    margin-bottom: 25px;
  }
}

.subtitulo {

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.texto {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7E7F80;
}
</style>
<template>

  <div class="container">

    <LoadingEmpresaContabilidade v-if="!usuario.Nome" :speed="1" :width="400" :height="160" style="margin-top: 25%;"
                                 class="text-center"></LoadingEmpresaContabilidade>

    <div v-else>

      <div class="row btnBackPage">
        <div class="d-flex align-items-center py-2 gap-sm-4">
          <BtnBackPage/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p class="subtitulo">
            Passo 02 de 02
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <p class="titulo">
            Agora vamos definir seu acesso na plataforma da Easyanest.
          </p>
        </div>
      </div>
      <form id="app">

        <div class="row">
          <div class="col-sm-4">

            <div class="form-group  mt-2">
              <label for="email" class="label_form"> E-mail </label>

              <input type="email" class="form-control form-easyanest" autocomplete=" nope" v-model.trim="email"
                     aria-describedby="cnpj-help-block" placeholder="Digite seu e-mail" id="email" name="email"/>
              <span class="help_text" v-if="v$.email.$error">
                {{ v$.email.$errors[0].$message }}
              </span> <span class="help_text" v-else>&nbsp;</span>


            </div>
            <div class="form-group mt-2">
              <label for="senha" class="label_form">Senha</label>

              <div class="input-group">
                <input v-bind:type="[showPassword ? 'text' : 'password']" v-model="senha"
                       class="form-control form-easyanest  border-end-0 "
                       placeholder="Digite sua senha"
                       id="senha"
                       name="senha">
                <button
                    class="btn bg-white border-top border-end border-bottom"
                    type="button" id="button-addon2"
                    @click="showPassword = !showPassword">
                  <ph-eye :size="18" v-if="showPassword"/>
                  <ph-eye-slash size="18" v-else/>
                  <i class="fa" :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
                </button>


              </div>

              <span class="help_text" v-if="v$.senha.$error">
                {{ v$.senha.$errors[0].$message }}
              </span> <span class="help_text d-block" v-else>&nbsp;</span>

              <span class="help_text d-block, texto">
                Sua senha deve ter no mínimo 8 caracteres sendo pelo menos 1 letra
                minúscula, 1 maiúscula e 1 número.
              </span>

            </div>

          </div>
        </div>

        <div class="row concordo py-4">
          <div class="col-sm-4">
            <div class="row">
              <div class="col-1">
                <div class="form-check">
                  <input class="form-check-input checkbox" type="checkbox" v-model="concordo" id="concordo"
                         name="concordo">

                </div>

              </div>
              <div class="col-11 ">
                <label class="frase text-start"> Li e concordo com a
                  <span class="nome_usuario" data-bs-toggle="modal" data-bs-target="#myModal2"> Política de
                    Privacidade</span> e com os
                  <span class="nome_usuario" data-bs-toggle="modal" data-bs-target="#myModal">Termos de Uso</span>.
                </label>

                <span class="help_text" v-if="v$.concordo.$error">
                  {{ v$.concordo.$errors[0].$message }}
                </span>

                <TermoUso/>
                <PoliticaPrivacidade/>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 1%;">
          <div class="col-sm-5">
            <vue-recaptcha sitekey="6LcQcGkjAAAAAH2fMJlVB8iLFYEi6oL4twqVgBFK" class="recaptcha"></vue-recaptcha>
          </div>
        </div>

        <hr style="border: 1px solid #EEEEEE;">

        <div class="row" style="margin-top: 5%;">
          <div class="col-sm-5">
            <span v-if="terminar_cadastro" v-html="terminar_cadastro_html" class="is-invalid-text text-center d-block"></span>
            <span v-else></span>
            <button type="button" class="botao_criar_conta" @click="TermincarCadastro">
              Criar conta
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>

</template>
<style>


.form-check-input[type="checkbox"] {
  border-radius: 50% !important;
}

.concordo {

}

@media (max-width: 570px) {
  .concordo {

  }
}

.checkbox {
  background-color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}


body {
  background-color: #FFFFFF !important;
}

.nome_usuario {
  color: #41B7FF;
}


@media (max-width: 570px) {
  .titulo {
    font-size: 20px;
    line-height: 22px;
  }
}

.frase {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 146%;
  /* identical to box height, or 24px */
  color: #242B30;
}


.botao_criar_conta_sem_btn {
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 112%;
  border-radius: 32px;
  color: #41B7FF;
  background-color: #FFFFFF;
  border: none;
}

.help_text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  color: #C0C0C0;
}

.input-group-text {
  background-color: #FFF;
  border: none;
}

.btnBackPage {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 575px) {
  .btnBackPage {

  }
}

.inputs-lg {
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

@media (max-width: 575px) {
  .pd-inputs {
    margin: 1%;
  }
}

.recaptcha {

}

@media (max-width: 575px) {
  .recaptcha {

  }
}

</style>
<script>
import {VueRecaptcha} from 'vue-recaptcha';
import appConfig from "../../../../app.config";
//import axios from 'axios';
import LoadingEmpresaContabilidade from "@/components/loading/LoadingEmpresaContabilidade.vue";
import consultaAPICNPJ from "consultar-cnpj";
import BtnBackPage from "@/components/BtnBackPage.vue";
import useValidate from "@vuelidate/core";
import {required, helpers, email, minLength, sameAs} from '@vuelidate/validators';
import TermoUso from "@/components/TermoUso.vue";
import PoliticaPrivacidade from "@/components/PoliticaPrivacidade.vue";
import axios from "axios";
import {PhEye} from "phosphor-vue";
import Swal from "sweetalert2";


export default {
  directives: {
    PhEye
  },
  components: {
    PoliticaPrivacidade,
    TermoUso,
    VueRecaptcha,
    BtnBackPage,
    LoadingEmpresaContabilidade
  },
  page: {
    title: "Compartilhamento",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {
    return {
      usuario: {
        Nome: null,
      },
      email: '',
      senha: '',
      showPassword: false,
      concordo: false,
      alerta: {
        hidden: false
      },
      thisModal: '',
      GOOGLE_RECAPTCHA_SITE: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE,
      terminar_cadastro: false,
      terminar_cadastro_html: null,
    }
  },
  created() {

    const hash = localStorage.getItem('hash');
    const usuario = JSON.parse(localStorage.getItem('info_usuario'));
    this.usuario.Nome = usuario.Nome;

    if (!hash) {
      this.$router.push('./');
    }
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  methods: {
    async consultaCNPJAPI(i) {

      try {
        this.v$.$validate();
        const cnpj = i.target.value;
        const clean = cnpj.replace(/[^0-9,]*/g, '').replace(',', '.');

        if (clean.length >= 14) {
          const empresa = await consultaAPICNPJ(clean);


          if (!empresa.status) {
            this.razao_social = empresa.razao_social;
            this.telefone = empresa.estabelecimento.ddd1 + ' ' + empresa.estabelecimento.telefone1;
          } else {
            this.v$.CNPJ.$error = true;
            this.v$.CNPJ.$errors[0].$message = empresa.detalhes
          }

          localStorage.setItem('empresa', JSON.stringify(empresa));

        } else {
          this.v$.CNPJ.$error = true;
          this.v$.CNPJ.$errors[0].$message = 'CNPJ inválido'

        }

      } catch (e) {
        console.log(e);
      }
    },
    async TermincarCadastro() {
      try {

        //Valida os dados
        this.v$.$validate();
        if (this.v$.$error) {
          return console.log('Sem erros no formulario');
        } else {
          console.log('Formulario com erros');
        }
        //Verifica o concordo da tela
        if (!this.concordo) {
          this.v$.concordo.$error = true;
          this.v$.concordo.$errors[0].$message = 'Campo obrigatório'
          return console.log('Não concordo com o termos');
        }

        let cadastro = JSON.parse(localStorage.getItem('form_cadastro'));
        let empresa = JSON.parse(localStorage.getItem('empresa'));
        let info_usuario = JSON.parse(localStorage.getItem('info_usuario'));
        let info_novo_usuario = {
          email: this.email,
          senha: this.senha,
          concordo: this.concordo
        }

        await axios.post(process.env.VUE_APP_API + '/contabilidade/novocadastro', {
          nome: cadastro.contato,
          razao_social: cadastro.razao_social,
          Telefone: cadastro.telefone_contato,
          email: info_novo_usuario.email,
          password: info_novo_usuario.senha,
          empresa,
          info_usuario,
          info_novo_usuario
        }).then((data) => {
          console.log(data);
          this.showAlerta('Cadastro realizado com sucesso', data.data.message, 'success');
          if(data.data.status) {
            window.location = 'https://testanest.breathe.med.br/Login.php?s=cta';
          }
        });

      } catch (e) {

        this.terminar_cadastro = true;
        this.terminar_cadastro_html = e.response.data.error;

        console.log(e);
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    showAlerta(texto,message, icon) {
      Swal.fire({
        icon: icon,
        title: texto,
        html: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
  validations() {

    //const MessageForm = (value) =>  !helpers.req(value);
    //const MessageFormMin = (value) =>  !helpers.req(value);

    return {
      email: {
        email: helpers.withMessage('Favor preencher com e-mail válido', email),
        required: helpers.withMessage('Favor preencher com o nome da empresa responsável', required)
      },
      senha: {
        minLength: helpers.withMessage('Favor preencher mínimo 8 caracteres', minLength(8)),
        required: helpers.withMessage('Campo obrigatório', required)
      },
      concordo: {
        sameAs: helpers.withMessage('Campo obrigatório', sameAs(true))
      }

    }
  },
  validationConfig: {
    $lazy: true,
  },
  setup() {
    return {v$: useValidate()}
  },
}
</script>

<template>
  <div id="myModal" class="modal right fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>

        </div>
        <div class="modal-body">
          <h5 class="titulo">
            Termos de uso
          </h5>
          <p class="texto">
            Este instrumento regula as condições de uso dos serviços do aplicativo EASYANEST
            e do site https://easyanest.com.br/, ambos simplesmente denominados como EASYANEST, servindo este como um
            contrato entre os nossos usuários e nós.
            A utilização de nossos serviços indica expressamente que você concorda com todos os termos e condições
            contidos neste instrumento e com as disposições legais aplicáveis
            à espécie. Você, como usuário, entende que o modelo de contratação dos serviços será por meio de planos
            pré-pagos de assinatura (mensais recorrentes ou anuais), oferecidos em https://easyanest.com ou em nossos
            aplicativos. O USUÁRIO ENTENDE E CONCORDA QUE A EASYANEST CONSIDERARÁ O USO DOS SERVIÇOS DISPOSTOS EM NOSSO
            SITE E EM NOSSOS APLICATIVOS COMO ACEITAÇÃO DESTES TERMOS DE USO E SERVIÇOS E DE TODAS AS DEMAIS DISPOSIÇÕES
            LEGAIS PERTINENTES À ESPÉCIE. AO ACEITAR OS TERMOS DO PRESENTE INSTRUMENTO, O USUÁRIO AUTORIZA EXPRESSAMENTE
            O TRATAMENTO DE SEUS DADOS PARA MANUTENÇÃO E BOM DESEMPENHO DAS FUNCIONALIDADES DA PLATAFORMA
            DA EASYANEST E CONSENTE EXPRESSAMENTE COM TAL TRATAMENTO.
            Caso você NÃO CONCORDE com os termos previstos neste instrumento, NÃO CLIQUE EM "CADASTRE-SE", nem acesse,
            visualize, baixe ou utilize de qualquer forma nenhuma página, conteúdo, informação ou serviço da EASYANEST.
            A utilização de nossos serviços indica expressamente que você concorda com todos os termos e condições
            contidos neste instrumento e com as disposições legais aplicáveis à espécie.
            Os presentes termos estão disponíveis para leitura, a qualquer momento em nosso site e aplicativos.
          </p>

          <h6>
            1. Quem somos e o que fazemos:
          </h6>
          <p class="texto">
            Somos uma empresa de tecnologia B2B2C que soluciona dores dos processos
            de trabalho da equipe cirúrgica e de instituições hospitalares, por meio da transformação digital de
            processos, otimização da experiência profissional e aumento da segurança do paciente cirúrgico. Nosso
            diferencial é atuar também fora do ambiente hospitalar, no sucesso do cliente e no aumento do ticket médio
            do anestesiologista. Destacam-se como nossos principais valores: economia de tempo, precisão de faturamento,
            análise de dados sensíveis e otimização de performance pré, per e pós operatória.
            Mais que uma plataforma: somos um ecossistema de otimização da assistência
            ao paciente cirúrgico.
          </p>
          <h6>
            2. Das condições gerais de uso:
          </h6>
          <p class="texto">
            A EASYANEST possibilita que os usuários tenham acesso a diversos recursos e funcionalidades disponibilizadas
            por nós, podendo utilizá-los através da versão trial (teste grátis) e limitado no prazo de 07 (sete) dias a
            partir do primeiro acesso ou da contratação de um plano específico, podendo a EASYANEST alterar a
            disponibilidade, funcionalidades e prazo da versão trial a sua livre escolha, sem a anuência ou qualquer
            aviso ao USUÁRIO. NO ENTANTO, A EASYANEST PODERÁ, A SEU ÚNICO E EXCLUSIVO CRITÉRIO, CONDICIONAR O ACESSO A
            DETERMINADAS FUNCIONALIDADES DO SITE E/OU DOS APLICATIVOS AO DEVIDO PAGAMENTO DE PLANOS. ASSIM SENDO, OS
            USUÁRIOS ENTENDEM QUE NEM TODOS OS RECURSOS DISPONÍVEIS NA PLATAFORMA SÃO GRATUITOS, PODENDO A EASYANEST
            ALTERNAR OS VALORES E FUNCIONALIDADES QUE O PLANO CONTEMPLA. É de responsabilidade do USUÁRIO ter acesso à
            tecnologia necessária para garantir o bom funcionamento de nossa plataforma. A EASYANEST poderá guardar,
            acessar e processar alguns dados fornecidos por você, quem nos concede, desde já, a permissão para fazer
            isso diretamente e também através de terceiros de nossa confiança e nos autoriza a realizar estudos
            estatísticos e de outras naturezas .Ressalte-se que seus dados pessoais jamais poderão ser compartilhados
            com o público em geral de forma a identificá-lo como usuário. Você, como nosso usuário, isenta a EASYANEST
            de quaisquer responsabilidades decorrentes do mau uso da nossa plataforma e pelos danos e prejuízos de toda
            natureza que possam decorrer da transmissão, difusão, disponibilização, recepção, obtenção ou acesso às
            informações. Nossa responsabilidade restringe-se apenas ao bom e correto funcionamento do nosso site e dos
            nossos aplicativos e de suas funcionalidades, não detendo a EASYANEST nenhuma responsabilidade sobre: (i)
            danos decorrentes da impossibilidade de uso da plataforma; (ii) mal preenchimento dos dados fornecidos por
            nossos usuários quando do momento do cadastro em nosso(s) sistema(s) e/ou de (iii) qualquer outro ato ou
            fato decorrente da conduta dos usuários. Todas os serviços comercializados através do nosso site e dos
            nossos aplicativos são de caráter temporário, válidas pelo período pago pelo usuário, sendo que o não
            pagamento de qualquer novo período ensejará o cancelamento imediato do acesso ao serviço contratado, que
            somente será reativado mediante a comprovação da quitação do débito. De toda a sorte, você pode cancelar seu
            plano/assinatura mensal recorrente sempre que quiser. O usuário entende que haverá a atualização constante
            de serviços ofertados através do nosso site e dos nossos aplicativos, de forma automática sempre que houver
            lançamento de nova versão ou correção de problemas técnicos, podendo, assim, ocorrer, a qualquer tempo
            mudanças nos serviços, em decorrência de atualizações de versão, com o que consente o usuário, ao contratar
            a licença de qualquer serviço. O usuário declara estar ciente que pequenos defeitos de programação são
            comuns a todos os softwares, isentando, assim, a EASYANEST, de qualquer responsabilidade por danos
            decorrentes de tais defeitos usuais, limitando a responsabilidade da EASYANEST à correção desses pequenos
            defeitos de programação. No momento do cadastro, os usuários poderão utilizar todos os serviços contratados
            em seu(s) plano(s) declarando, para tanto, terem lido, compreendido e aceitado todos os dispositivos
            contidos neste Termos de Uso e Serviços.
          </p>
          <h6>
            3. Do cadastro:
          </h6>
          <p class="texto">
            Os serviços oferecidos pela EASYANEST estão disponíveis para pessoas físicas absolutamente capazes (em
            nossos serviços business to consumer- B2C)) e pessoas jurídicas devidamente inscritas no CNPJ (em nossos
            serviços business to business- B2B). É necessário o preenchimento completo de todos os dados solicitados no
            momento do cadastramento, para que os usuários estejam habilitados a utilizar nossos serviços e
            funcionalidades. Para os USUÁRIOS realizarem o seu cadastro, eles, obrigatória e necessariamente fornecerão
            à EASYANEST os seguintes dados:
            (i) Nome completo
            (ii) CPF
            (iii) Número do registro profissional do Conselho Regional de Medicina e sua especialidade (já que a nossa
            maior "persona" são os anestesiologistas e cirurgiões) e demais registro de identidade de instrumentadores
            e/ou de outros usuários que não sejam medicos
            (iv) Endereço residencial
            (v) Dados de cartão de crédito em caso de opção por assinatura paga e com pagamento por meio desta forma
            A coleta dos seus dados como usuário visa identificá-lo, bem como habilitá-lo ao correto uso do nosso site e
            dos nossos aplicativos, e, com isto, a EASYANEST poderá assegurar a boa qualidade dos serviços contratados.
            O USUÁRIO poderá ter acesso às informações coletadas sobre o TRATAMENTO DE DADOS realizado pela EASYANEST,
            de forma gratuita, através da sua conta na PLATAFORMA, ou solicitação pelo e-mail: suporte@easyanest.com.br,
            podendo editá-las ou excluí-las a qualquer tempo. Ao consentir com os termos do presente instrumento, o
            USUÁRIO declara expressamente estar ciente que a coleta dos seus dados é primordial para o bom funcionamento
            do nosso site e dos nossos aplicativos, autorizando, desde já, seu tratamento de dado. É de exclusiva
            responsabilidade dos usuários fornecer, atualizar e garantir a veracidade dos dados cadastrais, não recaindo
            à EASYANEST qualquer tipo de responsabilidade civil e criminal resultante de dados inverídicos, incorretos
            ou incompletos fornecidos pelos usuários. A EASYANEST reserva-se ao direito de utilizar todos os meios
            válidos e possíveis para identificar seus usuários, bem como de solicitar dados adicionais e documentos que
            sejam necessários com o fim de conferir os dados informados. Neste caso, o uso do nosso site e dos nossos
            aplicativos pelo usuários fica condicionado ao envio dos documentos eventualmente solicitados. O usuário
            acessará a sua conta na plataforma por meio de login e senha, comprometendo-se a não informar a terceiros
            estes dados, responsabilizando-se integralmente pelo uso que deles seja feito. O usuário compromete-se a
            notificar a EASYANEST imediatamente, por meio dos canais de contato mantidos pela EASYANEST na PLATAFORMA,
            preferencialmente pelo seu canal de ajuda disponibilizado na PLATAFORMA, a respeito de qualquer uso não
            autorizado de sua conta. O usuário compromete-se a notificar a EASYANEST imediatamente, por meio dos canais
            de contato mantidos pela EASYANEST na PLATAFORMA, a respeito de qualquer conhecimento de irregularidades de
            outros usuários que possam ocasionar danos aos próprios USUÁRIOS do nosso site e dos nossos aplicativos, a
            esta, a EASYANEST ou a terceiros. O usuário será o único responsável pelas operações efetuadas em sua conta,
            uma vez que o acesso só será possível mediante a utilização de senha de seu exclusivo conhecimento. Ao seu
            exclusivo critério, a EASYANEST poderá excluir, inabilitar, suspender, bloquear, por tempo indeterminado,
            sem contrapartida indenizatória, cadastros de usuários que violem os termos deste instrumento ou a
            legislação em vigor, enviando um aviso justificado ao usuário em tais casos após a ação adotada. A EASYANEST
            reserve-se ao direito de não permitir novo cadastro de usuários que já tenham sido cancelados, inabilitados,
            bloqueados, excluídos ou suspensos do nosso site e dos nossos aplicativos. Não se permitirá, ainda, a
            criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados, bloqueados,
            inabilitados, excluídos ou suspensos por infrações às políticas da EASYANEST ou a legislação vigente. A
            EASYANEST reserva-se ao direito de, unilateralmente, sem prévio aviso, anuência ou contrapartida
            indenizatória, recusar qualquer solicitação de cadastro de um usuário, bem como cancelar, inabilitar,
            bloquear, excluir ou suspender o uso de um cadastro previamente aceito. Ao concordar com o presente
            instrumento, o usuário declara estar ciente de que é o único responsável pelo seu cadastro, sendo certo que
            qualquer prejuízo causado pela inserção de informações desatualizadas, inexatas ou inverídicas, não poderão
            ser imputados à EASYANEST.
          </p>
          <h6>
            4. Dos serviços e funcionalidades do nosso site e dos nossos aplicativos:
          </h6>
          <p class="texto">
            São estas as principais funcionalidades oferecidas pela EASYANEST:
            1- Gestão de processos;
            2- Cálculo dinâmico de precificação de anestesia com base na tabela CBHPM
            e de novos valores, em tempo ótimo, bem como o documento com a descrição
            do faturamento do profissional com a retenção dos impostos devidos;
            3- Emissão de notas fiscais;
            4- Registro eletrônico de procedimenros anestésicos e emissão de relatórios;
            5- Documentação com o logotipo do usuário anestesiologista, instrumentador
            ou cirurgião;
            6- Assinaturas digitais;
            7- Banco de dados individuais
          </p>
          <h6>
            5. Propriedade Intelectual:
          </h6>
          <p class="texto">
            Todos os materiais, patentes, marcas, registros, domínios, nomes, privilégios, criações, imagens e todos os
            direitos conexos e relacionados com site e com os aplicativos de titularidade e desenvolvidos pela
            EASYANEST, são e permanecerão de única e exclusiva propriedade da EASYANEST, concordando os usuários em não
            praticar ato ou fato que, por qualquer modo, prejudique os direitos previstos aqui e tampouco reivindicar
            qualquer direito ou privilégio sobre os mesmos. Reservamo-nos ao direito de, a qualquer momento e a nosso
            exclusivo critério, modificar as condições de acesso à plataforma, alterando seu design e/ou sua forma de
            apresentação, incluindo ou suprimindo funcionalidades e/ou informações, ou realizando qualquer outra
            alteração, de qualquer ordem, sem que tal fato resulte em qualquer direito do usuário reclamar perdas e
            danos, a qualquer título.

          </p>
          <h6>
            6. Suporte técnico:
          </h6>
          <p class="texto">
            A EASYANEST prestará suporte aos seus usuários de 2ª a 6ª feira em horário comercial (08h00 – 18h00 horário
            de Brasília) através do e-mail suporte@easyanest.com.br ou na própria Plataforma por meio da central de
            ajuda por WhatsApp ou outros aplicativos de comunicação.
          </p>
          <h6>
            7. Cancelamento:
          </h6>
          <p class="texto">
            Para solicitar o cancelamento de qualquer serviço de nosso site ou aplicativo(s), no plano mensal, o usuário
            deverá enviar um e-mail para a equipe de suporte ou uma mensagem no whatsapp que consta em nossos sistemas.
            Só poderão ser cancelados os recursos ou funcionalidades contratadas na PLATAFORMA, perdendo acesso apenas
            ao item cancelado. O cancelamento só de dará em caso de não haver pendências financeiras. Em caso de
            assinatura anual, se o usuário deixar de pagar uma parcela, sua assinatura será automaticamente extinta e o
            valor previamente pago à vista não será restituido e se for parcelado, as demais parcelas continuarão a ser
            cobradas via cartão de crédito. Se o usuário optar pelo pagamento do plano anual mediante boleto bancário, a
            EASYANEST, após 7 dias de seu inadimplemento, poderá ajuizar, ao seu critério ação judicial de cobrança com
            incidência de juros moratórios e compensatórios.
          </p>
          <h6>
            8. Métodos de pagamento:
          </h6>
          <p class="texto">
            Para o pagamento devido pela contratação de assinatura na Plataforma,
            deverá o usuário escolher por um dos métodos a seguir:
            1- Boleto bancário
            2- Pix
            3- Pagamento eletrônico
            4- Cartão de crédito, sendo necessária a coleta, por parte da EASYANEST, do nome do titular do cartão de
            crédito, o número do cartão, a Bandeira, o Código verificador, o prazo de expiração e endereço para onde a
            fatura é emitida O usuário obriga-se a manter atualizados seus dados cadastrais, bem como informar qualquer
            modificação verificada, especialmente seus dados de pagamento, bem como seu endereço de e-mail e telefone os
            quais serão os principais canais de comunicação entre a EASYANEST e o usuário.
          </p>
          <h6>
            9. Foro:
          </h6>
          <p class="texto">
            O presente instrumento constitui o entendimento integral entre o usuário e a EASYANEST e é regido pelas Leis
            Brasileiras, ficando eleito o foro da comarca da capital do Rio de Janeiro como único competente para
            dirimir questões decorrentes do presente instrumento, com renúncia expressa a qualquer outro foro, por mais
            privilegiado que seja.
            Fim.
          </p>
        </div>


      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>


export default {
  name: "TermoUso",
  props: {
    visible: Boolean,
    variant: String,
  },
  data() {
    return {
      OpenClose: this.visible
    }
  },
  methods: {
    OpenCloseFun() {
      this.OpenClose = false;
    },
  },
  watch: {
    visible: function (newVal, oldVal) { // watch it
      this.OpenClose = newVal;
      console.log('new' + newVal + '==' + oldVal)
    }
  }
}
</script>

<style scoped>
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal {
  --bs-modal-width: 712px;
  min-width: 320px;
}

.btn-close {
  margin: 40px 0 10px 0px;
  position: absolute;
}

.modal-title {}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 600px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 48px 48px 80px;
  margin-top: 50px;
}

@media (max-width: 570px) {
  .modal.right .modal-body {
    padding-left: 230px;
    margin-top: -50px;
  }
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -620px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 24px 0px 0px 24px;
  border: none;


}

.modal-header {
  border-bottom-color: #EEEEEE;

}

/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909C;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #FFFFFF;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer>a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.titulo {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}


@media (max-width: 570px) {
  .titulo {
    font-size: 20px;
    padding: 0px;
    margin-bottom: 25px;
  }
}

.subtitulo {

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;


}

.texto {

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7E7F80;


}
</style>